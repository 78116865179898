import Header from './Header';
import AddMadlib from './AddMadlib';
import Content from './Content';
import Footer from './Footer';
import { useState } from 'react';
import { GiBroadsword, GiMagicPalm, GiRosaShield } from 'react-icons/gi';

function App() {

  const [selection, setSelection] = useState(0);
  const [confirmedSelection, setConfirmedSelection] = useState(null);
  
  const adventurers = [
    {
        id: 0,
        name: "Adra Hayes",
        icon: GiBroadsword,
    },
    {
        id: 1,
        name: "Scony Badger",
        icon: GiRosaShield,
    },
    {
        id: 2,
        name: "Chet, the Wizard",
        icon: GiMagicPalm,
    }
  ];

  // TODO - options for madlib prompts
  const options = {
    one: [],
    two: [],
    three: ["goblin", "bugbear", "kobold"],
  }

  // TODO - finish writing madlib
  const madlib = 'Far, far away from home, ' + adventurers[selection].name 
    + " was wandering. With their trusty " + options.one[0] + ', they are looking for a challenge!';


  const handleCheck = (id) => {
    setSelection(id);
  }

  const handleStart = () => {
    setConfirmedSelection(true);
  }

  const handleSubmit = (e) => {
    
  }

  return (
    <div className="App">
      <Header />
      <Content 
        adventurers={adventurers}
        selection={selection}
        confirmedSelection={confirmedSelection}
        handleCheck={handleCheck}
        handleStart={handleStart}
      />
      <Footer />
    </div>
  );
}

export default App;
