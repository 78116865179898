import AddMadlib from "./AddMadlib"
import AdventurerList from "./AdventurerList"

const Content = ({ adventurers, selection, confirmedSelection, handleCheck, handleStart }) => {

    return (
        <main>
            { confirmedSelection ? (
                <p>
                    Fill in the blanks!
                </p>
            ) : (            
                <p>
                    Choose your adventurer!
                </p>
            )}
            {/* TODO: create component that will prompt for each madlib field */}
            { confirmedSelection && (
                <p>
                    Pick a monster: goblin, kobold, or bugbear
                </p>
            )}
            { confirmedSelection ? (
                <AddMadlib
                />
            ) : (
                <AdventurerList
                adventurers={adventurers}
                selection={selection}
                handleCheck={handleCheck}
                />
            )}
            <input 
                className="continueButton"
                type="button"
                value="Continue"
                onClick={() => handleStart()}/>
        </main>
    )
}

export default Content
