import { FaPlus } from 'react-icons/fa';

const AddMadlib = () => {
    return (
        <form className="addForm">
            <label htmlFor='addMadlib'>Add Madlib</label>
            <input
                autoFocus
                id='addMadlib'
                type='text'
                placeholder="Your answer here..."
                required
            />
            <button
                className="formButton"
                type='submit'
                aria-label='Add Madlib'
            >
            <FaPlus />
            </button>
        </form>
    )
}

export default AddMadlib
