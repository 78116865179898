const Header = () => {
    
    return (
        <header>
            <h1>Maddragon</h1>
        </header>
    )
}

export default Header;
