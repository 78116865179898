const AdventurerList = ({ adventurers, selection, handleCheck }) => {
    return (
        <ul>
            {adventurers.map((adventurer) => (
                <li className="adventurer" key={adventurer.id}>
                    <input
                        className={'radio' + adventurer.id}
                        type="radio"
                        checked={adventurer.id === selection ? true : false}
                        onChange={() => handleCheck(adventurer.id)}/>
                    <label>{adventurer.name}</label>
                    <adventurer.icon
                        role="button"
                        tabIndex="0" />
                </li>
            ))}
        </ul>
    )
}

export default AdventurerList
